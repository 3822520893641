import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import { LabelProvider, Main, MainContent, Overlay, THeaderOpenSection, ThemeProvider } from 'telia-front-react';
import { AppFooter, AppHeader } from 'telia-megamenu';
import { CoreConfig } from '@teliaee/sf.core';
import RouteProps from './common/types/RouteProps';
import ProtectedRoute from './ProtectedRoute';
import ErrorBoundary from './common/components/error/boundary';
import ErrorView from './common/components/error/view';

import createRootStore, { RootStore } from './common/root/init';
import RootStoreContext from './common/root';
import NotFound from './common/components/notFound';
import AuthRedirect from './common/components/redirect';
import Head from './common/components/head';
import Dashboard from './pages/dashboard';
import { Loadable } from './common/utils/loadable';
import { routes } from './pages';
import { DASHBOARD, TECHNICIAN } from './common/constants/route';
import Technician from './pages/technician';

/* istanbul ignore next 6 */
export const getIconPath = (name: string) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const req = require('@telia-front/icon/lib/svg/icons.svg');
  return req + name;
};
const overlay = <Overlay spinner />;

@observer
export default class App extends React.Component {
  state = {
    hasError: false,
  };

  private static META_TAGS = [];

  private loadableRootStore = Loadable.fromLoad(() => createRootStore({ routes }));

  private static renderProtectedRoute(route: RouteProps): React.ReactElement<ProtectedRoute> {
    return (
      <ProtectedRoute
        exact
        key={route.key}
        routeKey={route.key}
        path={route.path}
        feature={route.feature}
        render={App.routeRenderProp.bind(App, route)}
      />
    );
  }

  private static routeRenderProp(route: RouteProps, routeComponentProps: RouteComponentProps): React.ReactChild {
    return !route.validate || route.validate(routeComponentProps.match) ? (
      App.renderRoute(route, routeComponentProps)
    ) : (
      <Route component={NotFound} />
    );
  }

  private static renderRoute(route: RouteProps, routeComponentProps: RouteComponentProps): React.ReactElement<React.ReactFragment> {
    return (
      <>
        <Head {...route.head} />
        <route.component {...routeComponentProps} />
      </>
    );
  }

  static renderApp = (rootStore: RootStore, hasError: boolean, onError: (value: boolean) => void) => {
    const { ssoStore, localeStore, navigationStore, translateStore, megamenuStore } = rootStore;
    const { environment, isBrowser } = CoreConfig;

    const titleHtml = translateStore.translateGlobalHtml('404.title');
    const titleMessageHtml = translateStore.translateHtml('error.backend.general');
    const button1Text = translateStore.translate('error.backend.general.retry');
    const button2Text = translateStore.translate('error.backend.general.goto.home');

    const headerFooterCommonProps = {
      app: 'selfservice',
      /* istanbul ignore next */
      tier: environment === 'local' ? 'dev' : environment,
      lang: localeStore.currentLocale,
    };

    /* istanbul ignore next 5 */
    const handleHeaderSectionChange = (openSection: THeaderOpenSection) => {
      if (openSection !== 'sso') {
        rootStore.ssoStore.dismissSignIn();
      }

      megamenuStore.setOpenSection(openSection);
    };

    return (
      <LabelProvider labels={translateStore.translations.global}>
        <RootStoreContext.Provider value={rootStore}>
          <ThemeProvider getIconPath={getIconPath}>
            <Provider {...rootStore.stores} rootStore={rootStore}>
              <>
                <Head meta={App.META_TAGS} />
                <Router history={rootStore.navigationStore.history}>
                  <Main
                    consentEnabled={
                      /* istanbul ignore next */ rootStore.featureStore.enableNewCookieBot
                        ? rootStore.cookieConsentStore.isCookiebotEnabled
                        : undefined
                    }
                    consent={
                      /* istanbul ignore next */ rootStore.featureStore.enableNewCookieBot
                        ? { ...rootStore.cookieConsentStore.cookieConsents }
                        : undefined
                    }
                  >
                    <>
                      {(ssoStore.loaded || ssoStore.loadIsSlow) && (
                        <>
                          {isBrowser && (
                            <AppHeader
                              {...headerFooterCommonProps}
                              skipSsoModule
                              isSPA
                              onPathChange={navigationStore.onPathChange}
                              currentPath={navigationStore.location.pathname}
                              ssoClientState={ssoStore.state}
                              customerType={ssoStore.contextOrCustomerSegment}
                              ssoClient={ssoStore.ssoClient}
                              loginOptions={ssoStore.ssoModalProps}
                              onLangChange={localeStore.setLocale}
                              onClientStateChange={ssoStore.setState}
                              showEnLang
                              openSection={megamenuStore.openSection}
                              onSectionChange={handleHeaderSectionChange}
                            />
                          )}
                          <MainContent>
                            <Switch>
                              <ErrorBoundary onError={onError}>
                                {hasError ? (
                                  <ErrorView
                                    titleHtml={titleHtml}
                                    titleMessageHtml={titleMessageHtml}
                                    button1Text={button1Text}
                                    button2Text={button2Text}
                                  />
                                ) : (
                                  App.renderMainView()
                                )}
                              </ErrorBoundary>
                            </Switch>
                          </MainContent>
                          {isBrowser && <AppFooter {...headerFooterCommonProps} onPathChange={navigationStore.navigateTo} />}
                        </>
                      )}
                    </>
                  </Main>
                </Router>
              </>
            </Provider>
          </ThemeProvider>
        </RootStoreContext.Provider>
      </LabelProvider>
    );
  };

  private static renderMainView(): JSX.Element {
    return (
      <AuthRedirect>
        <React.Suspense fallback={overlay}>
          <Switch>
            <Route exact path={DASHBOARD} component={Dashboard} />
            <Route exact path={TECHNICIAN} component={Technician} />
            {routes.map(App.renderProtectedRoute)}
            <Route component={NotFound} />
          </Switch>
        </React.Suspense>
      </AuthRedirect>
    );
  }

  render() {
    const rootStore = this.loadableRootStore.data;

    if (rootStore && rootStore.ssoStore.loaded) {
      const { hasError } = this.state;

      return App.renderApp(rootStore, hasError, (value: boolean) => this.setState({ hasError: value }));
    }

    if (rootStore && rootStore.ssoStore.failure) {
      const titleHtml = rootStore.translateStore.translateGlobalHtml('404.title');
      const titleMessageHtml = rootStore.translateStore.translateHtml('error.backend.general');
      const button1Text = rootStore.translateStore.translate('error.backend.general.retry');
      const button2Text = rootStore.translateStore.translate('error.backend.general.goto.home');

      return (
        <RootStoreContext.Provider value={rootStore}>
          <ErrorView titleHtml={titleHtml} titleMessageHtml={titleMessageHtml} button1Text={button1Text} button2Text={button2Text} />
        </RootStoreContext.Provider>
      );
    }

    return (
      <Main>
        <Overlay spinner type="fixed" />
      </Main>
    );
  }
}
